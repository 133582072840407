import React from 'react';
import './Shop.css';
import AboutImage from "../../assets/shop/sklep.png";
import one from '../../assets/shop/1.png'
import two from '../../assets/shop/2.png'
import three from '../../assets/shop/3.png'
import four from '../../assets/shop/4.png'
import five from '../../assets/shop/5.png'
import six from '../../assets/shop/6.png'

export default function Shop() {
    return (
        <>
            <div id="shop">
                <div className="container">
                    <div className="title_headling">
                        <h3 style={{textAlign: 'center'}}>Sklep - „Antystresowa Armia”</h3>
                        <p>Witamy w Sklepie Antystresownia – Twojej przestrzeni na zakupy, która łączy
                            styl z funkcjonalnością i inspiracją. Nasza kolekcja „Antystresowa Armia”** to
                            idealne rozwiązanie dla każdego, kto chce wprowadzić odrobinę pozytywnej
                            energii do swojego codziennego życia oraz wesprzeć swoje samopoczucie.</p>
                    </div>
                    <div className="about_image">
                        <img src={AboutImage} alt="about" className="about_main"/>
                    </div>
                    <div className="service_wrapper">
                        <div className="service_box">
                            <img src={one} alt="one" className="service_icon"/>
                            <h4 className="number">01</h4>
                            <div className="service_content">
                                <h5 style={{ marginTop: '0'}}>Bluzy i koszulki</h5>
                                <p>Spersonalizowane rysunki! Nasze ubrania są stworzone z
                                    myślą o komforcie i stylu. Wybierz jedną z naszych bluz i koszulek, które z
                                    motywującymi hasłami przypomną Ci, że jesteś częścią Antystresowej Armii.
                                    Idealne na każdą okazję – zarówno na codzienne wyjścia, jak i relaks w
                                    domowym zaciszu.</p>
                            </div>
                        </div>
                        <div className="service_box">
                              <img src={two} alt="two" className="service_icon"/>
                            <h4 className="number">02</h4>
                            <div className="service_content">
                                <h5 style={{ marginTop: '0'}}>Plakaty i pocztówki</h5>
                                <p>Ozdób swoje wnętrza inspirującymi plakatami i
                                    pocztówkami. Nasze produkty są pełne pozytywnych przesłań i motywujących
                                    grafik, które pomogą Ci utrzymać optymizm i dobrą energię każdego dnia.</p>
                            </div>
                        </div>
                        <div className="service_box">
                             <img src={three} alt="three" className="service_icon"/>
                            <h4 className="number">03</h4>
                            <div className="service_content">
                                <h5 style={{ marginTop: '0'}}>Kubki</h5>
                                <p>Rozpocznij dzień od ulubionej kawy czy herbaty w naszym kubku z
                                    hasłem „Antystresowa Armia”. To doskonały sposób na przypomnienie sobie,
                                    jak ważne jest dbanie o siebie i swoje samopoczucie..</p>
                            </div>
                        </div>
                        <div className="service_box">
                             <img src={four} alt="four" className="service_icon"/>
                            <h4 className="number">04</h4>
                            <div className="service_content">
                                <h5 style={{ marginTop: '0'}}>Dodatkowe inspiracje na Ekologiczne torby</h5>
                                <p>Poznaj nasze ekologiczne torby „Antystresowe” – Wykonane z naturalnych materiałów, przyjaznych dla środowiska. Torby nie tylko pomogą Ci zadbać o planetę, ale również o Twoje samopoczucie.</p>
                            </div>
                        </div>
                        <div className="service_box">
                                <img src={five} alt="five" className="service_icon"/>
                            <h4 className="number">05</h4>
                            <div className="service_content">
                                <h5 style={{ marginTop: '0'}}>Słuchowiska</h5>
                                <p>Zanurz się w inspirujących opowieściach i relaksujących
                                    nagraniach, które pomogą Ci w codziennej walce ze stresem i poprawie jakości
                                    życia.</p>
                            </div>
                        </div>
                        <div className="service_box">
                         <img src={six} alt="six" className="service_icon"/>
                            <h4 className="number">06</h4>
                            <div className="service_content">
                                <h5 style={{ marginTop: '0'}}>Ebooki</h5>
                                <p>Znajdź książki i przewodniki, które dostarczą Ci cennych narzędzi do
                                    zarządzania stresem, poprawy samoświadomości oraz wzmocnienia poczucia
                                    własnej wartości.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="title_headling">
                    <h3>Dlaczego warto?</h3>
                    <h4>Dołącz do Antystresowej Armii i znajdź coś dla siebie, co pomoże Ci w
                        codziennej walce ze stresem, przyniesie uśmiech na Twoją twarz i pozytywną
                        energię do Twojego życia. Zapraszamy do odkrywania naszego
                        świata pełnego pozytywnych wibracji!</h4>
                    <div className="btn_wrapper">
                        <a  href='https://antystresownia.cupsell.pl/' target="_blank" rel="noopener noreferrer">
                            <button type="submit" className="btn">
                                Odwiedź nasz sklep
                            </button>
                        </a>

                    </div>
                </div>
            </div>
        </>
    )
}
