import React from 'react';
import './Services.css';
import one from '../../assets/services/1.png'
import two from '../../assets/services/2.png'
import three from '../../assets/services/3.png'
import four from '../../assets/services/4.png'
import five from '../../assets/services/5.png'
import six from '../../assets/services/6.png'
import seven from '../../assets/services/7.png'
import eight from '../../assets/services/8.png'

export default function Services() {
  return (
    <>
     <div id="services">
        <div className="container">
            <div className="title_headling">
                <h3>Zakres tematyczny naszych szkoleń</h3>
                <p>Nasze szkolenia i usługi są zaprojektowane z myślą o Twoim rozwoju i wsparciu
                    w radzeniu sobie z codziennymi wyzwaniami. Dołącz do naszej społeczności i
                    zyskaj dostęp do narzędzi, które pomogą Ci w budowaniu zdrowego i
                    zrównoważonego życia. Razem możemy tworzyć lepsze jutro!
                    Skontaktuj się z nami, aby dowiedzieć się więcej o naszej ofercie i jak możemy
                    pomóc Tobie lub Twojej organizacji.
                    Dołącz do Antystresowej Armii!</p>
            </div>
            <div className="service_wrapper">
                <div className="service_box">
                    <img src={one} alt='one 'className="service_icon"/>
                    <h4 className="number">01</h4>
                    <div className="service_content">
                        <h5 style={{ marginTop: '0'}}>"Stres pod Lupą: Zrozumieć, Pokonać, Wykorzystać"</h5>
                        <p>Zgłębimy naturę stresu, nauczymy Cię, jak go rozpoznać i skutecznie
                            zarządzać nim w codziennym życiu. Dowiedz się, jak przekształcić stres w
                            motywację i siłę do działania.</p>
                    </div>
                </div>
                <div className="service_box">
                   <img src={two} alt='two 'className="service_icon"/>
                    <h4 className="number">02</h4>
                    <div className="service_content">
                        <h5 style={{ marginTop: '0'}}>"Zrozumieć i Być Rozumianym: Mistrzowie Komunikacji"</h5>
                        <p>Odkryj tajniki efektywnej komunikacji i naucz się, jak lepiej rozumieć
                            innych oraz być zrozumianym. Warsztaty skupiają się na rozwijaniu
                            umiejętności słuchania, wyrażania myśli oraz budowania trwałych relacji.</p>
                    </div>
                </div>
                <div className="service_box">
                     <img src={three} alt='three 'className="service_icon"/>
                    <h4 className="number">03</h4>
                    <div className="service_content">
                        <h5 style={{ marginTop: '0'}}>"Cień w Biurze: Rozpoznawanie i Radzenie Sobie z Mobingiem"</h5>
                        <p>Dowiedz się, jak rozpoznać mobing w miejscu pracy, jakie są jego
                            konsekwencje oraz jak skutecznie przeciwdziałać i radzić sobie z takim
                            zachowaniem.</p>
                    </div>
                </div>
                <div className="service_box">
                  <img src={four} alt='four 'className="service_icon"/>
                    <h4 className="number">04</h4>
                    <div className="service_content">
                        <h5 style={{ marginTop: '0'}}>"Siła w Delikatności: Mistrzostwo Asertywności"</h5>
                        <p>Naucz się, jak być stanowczym i jednocześnie łagodnym. Warsztaty
                            pomagają rozwijać umiejętność wyrażania swoich potrzeb i granic w sposób,
                            który jest szanujący i efektywny.</p>
                    </div>
                </div>
                <div className="service_box">
                       <img src={five} alt='five 'className="service_icon"/>
                    <h4 className="number">05</h4>
                    <div className="service_content">
                        <h5 style={{ marginTop: '0'}}>"Wirtualne Cienie: Jak Stawiać Czoła Cyberprzemocy"</h5>
                        <p>Zdobądź wiedzę na temat cyberprzemocy, naucz się, jak ją rozpoznać i
                            jakie kroki podjąć, aby się przed nią bronić. Warsztaty oferują praktyczne
                            strategie ochrony w świecie online.</p>
                    </div>
                </div>
                <div className="service_box">
                              <img src={six} alt='six 'className="service_icon"/>
                    <h4 className="number">06</h4>
                    <div className="service_content">
                        <h5 style={{ marginTop: '0'}}>"Ratujmy Pasję: Jak Przetrwać i Pokonać Wypalenie"</h5>
                        <p>Dowiedz się, jak rozpoznać symptomy wypalenia zawodowego i jakie
                            kroki podjąć, aby odzyskać pasję i radość z pracy. Warsztaty oferują techniki
                            zapobiegania i radzenia sobie z wypaleniem.</p>
                    </div>
                      </div>
                                      <div className="service_box">
                              <img src={eight} alt='eight 'className="service_icon"/>
                    <h4 className="number">07</h4>
                    <div className="service_content">
                        <h5 style={{ marginTop: '0'}}>"Balans Życia: Sztuka Harmonii Między Pracą a Prywatnością"</h5>
                        <p>Odkryj, jak efektywnie łączyć życie zawodowe z prywatnym. Warsztaty
                            pomagają znaleźć równowagę i stworzyć harmonijną przestrzeń, w której
                            możesz prosperować zarówno w pracy, jak i w życiu osobistym..</p>
                    </div>
                      </div>
                <div className="service_box">
                                <img src={seven} alt='seven 'className="service_icon"/>
                    <h4 className="number">08</h4>
                    <div className="service_content">
                        <h5 style={{ marginTop: '0'}}>"Wartość w Lustrach: Klucz do Zdrowego Poczucia Własnej Wartości"</h5>
                        <p>Pracuj nad budowaniem i wzmacnianiem poczucia własnej wartości.
                            Warsztaty koncentrują się na rozwijaniu pozytywnego obrazu siebie i pewności
                            siebie.</p>
                    </div>
                </div>
                      <div className="service_box">
                       <img src={four} alt='four 'className="service_icon"/>
                    <h4 className="number">09</h4>
                    <div className="service_content">
                        <h5 style={{ marginTop: '0'}}>"Most Nadziei: Wsparcie w przeżywaniu żałoby"</h5>
                        <p>Grupa wsparcia.</p>
                    </div>
                      </div>
                             <div className="service_box">
                  <img src={three} alt='three 'className="service_icon"/>
                    <h4 className="number">10</h4>
                    <div className="service_content">
                        <h5 style={{ marginTop: '0'}}>"Nowy Początek"</h5>
                        <p>Odzyskiwanie Siebie po Zdradzie i Rozstaniu (grupa kobiet, grupa mężczyzn, grupa łączona)</p>
                    </div>
                      </div>
            </div>
        </div>
     </div>
    </>
  )
}
