import React from 'react';
import './About.css';
import AboutImage from '../../assets/about/siedlisko.png';
import individualConsultations from '../../assets/offert/konsultacje_indywidualne.png'
import groupConsultations from '../../assets/offert/konsultacje_grupowe.png'
import therapy from '../../assets/offert/terapia.png'
import training from '../../assets/offert/szkolenia.png'
import rownowaga from '../../assets/offert/rownowaga.png'
import spokoj from '../../assets/offert/spokoj.png'
import wiara from '../../assets/offert/wiara.png'

export default function About() {

  return (
    <>
    <section id="about">
        <div class="container">
            <div className="title_headling">
                <h3>Misja Antystresowni</h3>
                <p>Naszą misją jest wspieranie ludzi w rozwoju osobistym, redukowaniu stresu i
                    budowaniu wewnętrznej siły poprzez zastosowanie psychologii pozytywnej i
                    nowoczesnych technik terapeutycznych. W Antystresowni pomożemy Ci
                    zbudować równowagę, odzyskać spokój i wzmocnić wiarę we własne
                    możliwości.
                    Zaufaj nam i dołącz do nas w podróży ku lepszemu życiu pełnemu harmonii i
                    pozytywnej energii!</p>
            </div>
            <div className="about_box_wrapper">
                <div className="about_box">
          
                    <div className="about_content">
                        <h5>Zbudować równowagę</h5>
                          </div>
                              <img  className="about_icon" src={rownowaga} alt="rownowaga" />
                </div>
                <div className="about_box">
          
                    <div className="about_content">
                        <h5>Odzyskać spokój</h5>             
                          </div>
                            <img  className="about_icon" src={spokoj} alt="spokoj" />
                </div>
                                      <div className="about_box">
               
                    <div className="about_content">
                        <h5>Wzmocnić wiarę we własne możliwości</h5>                    
                          </div>
                            <img  className="about_icon" src={wiara} alt="wiara" />
                      </div>
            </div>

            <div className="about_box_details">
                <div className="about_col">
                    <div className="about_image">
                        <img src={AboutImage} alt="about" className="about_main"/>
                    </div>
                </div>
                <div className="about_col more_space">
                    <h3>OFERTA</h3>
                    <p>W Antystresowni, naszym celem jest wspieranie Twojego zdrowia psychicznego
                        i emocjonalnego poprzez specjalistyczne usługi dostosowane do Twoich
                        indywidualnych potrzeb oraz potrzeb Twojej organizacji. Oferujemy szeroki
                        zakres działań, które pomogą Ci w radzeniu sobie ze stresem, poprawie
                        komunikacji, zarządzaniu wypaleniem zawodowym i wielu innych wyzwaniach.
                        <br/>
                        <br/>
                        Nasza oferta obejmuje:</p>

                    <div className="grid_info">        
                        <div className="detail">                                
                          <div style={{display:'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                           <img  className="icon" src={individualConsultations} alt="Konsultacje indywidualne" />
                             <h4>Konsultacje indywidualne</h4>        
                          </div>
                        <p>Spersonalizowane sesje, które pozwolą Ci zgłębić i
                                rozwiązać osobiste problemy oraz wyzwania. Oferujemy zarówno spotkania
                                stacjonarne, jak i online, aby zapewnić Ci wygodny dostęp do naszych usług.</p>
                        </div>
                    </div>
                    <div className="grid_info">

                              <div >
                                       <div style={{display:'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                      <img className="icon" src={groupConsultations} alt="Warsztaty grupowe" />
                                        <h4>Warsztaty grupowe</h4>
                </div>
                          
                            <p>Warsztaty dla małych i dużych grup, które wspierają
                                rozwój umiejętności interpersonalnych i emocjonalnych. Nasze szkolenia są
                                zaprojektowane tak, aby przynieść korzyści zarówno dla zespołów w pracy, jak i
                                dla grup przyjaciół czy rodziny.</p>
                        </div>
                    </div>
                    <div className="grid_info">
    
                              <div className="detail">
                                        <div style={{display:'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                      <img className="icon" src={therapy} alt="Terapia" />
                                         <h4>Terapia krótkoterminowa</h4>
                </div>
                         
                            <p>Skoncentrowana na efektywnym i szybkim
                                rozwiązywaniu problemów psychicznych i emocjonalnych. Nasza terapia jest
                                idealna dla tych, którzy potrzebują szybkiej pomocy i praktycznych narzędzi do
                                zarządzania trudnymi sytuacjami.</p>
                        </div>
                    </div>
                    <div className="grid_info">

                              <div className="detail">
                                     <div style={{display:'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                      <img className="icon" src={training} alt="szkolenia" />
                                        <h4>Szkolenia dla instytucji</h4>
                </div>
                          
                            <p>Oferujemy dedykowane szkolenia dla firm, uczelni,
                                szpitali, szkół i urzędów, dostosowane do specyfiki i potrzeb danej organizacji.
                                Pomagamy w budowaniu zdrowych środowisk pracy oraz w rozwijaniu
                                umiejętności pracowników..</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
