import React from 'react';
import './Blog.css';
import BlogImage from '../../assets/blog/Antystresonia-eBook.png';

export default function Blog() {
    return (
        <>
            <section id="blog">
                <div className="container">
                    <div className="blog_wrapper">
                        <div className="blog_col">
                            <h3 style={{textAlign: 'center'}}>Korzyści jakie daje Siedlisko Siedź Blisko czyli obóz terapeutyczny na łonie natury</h3>
                            <ul style={{ marginLeft: '-8px'}}>
                                <li>
                                    Redukcja stresu i lęku: Badania pokazują, że przebywanie na łonie natury
                                    obniża poziom kortyzolu (hormonu stresu).
                                </li>
                                <li>
                                    Poprawa samopoczucia: Spędzanie czasu na świeżym powietrzu poprawia nastrój
                                    i zwiększa poziom endorfin.
                                </li>
                                <li>
                                    Zdrowie fizyczne: Aktywność na świeżym powietrzu sprzyja poprawie zdrowia
                                    fizycznego, w tym kondycji serca i układu oddechowego.
                                </li>
                                <li>
                                    Wzmocnienie więzi społecznych: Grupa ludzi wspólnie uczestnicząca w
                                    szkoleniu tworzy silne więzi społeczne, co jest ważne dla zdrowia psychicznego.
                                </li>
                            </ul>
                        </div>
                        <div className="blog_col">
                            <div className="blog_image">
                                <img src={BlogImage} alt="Blog" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
