import React from 'react';
import './Contact.css';
import ContactImage from '../../assets/contact/contact.png';

export default function Contact() {
    return (
        <>
            <section id="contact">
                <div className="container">
                    <div className="contact_wrapper">
                        <div className="contact_col">
                            <div className="contact_image">
                                <img src={ContactImage} alt="Contact" />
                            </div>
                        </div>
                        <div className="contact_col">
                            <h2 style={{ color: 'white'}}>KONTAKT</h2>
                            <p style={{ color: 'white'}}>Jeśli masz jakiekolwiek pytania, skontaktuj się z nami!</p>
                            <a href="mailto:kontakt@antystresownia.pl" className="contact_email">
                                kontakt@antystresownia.pl
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
