import React from 'react';
import './AboutHabitat.css';
import one from '../../assets/aboutHabbit/1.png'
import two from '../../assets/aboutHabbit/2.png'
import three from '../../assets/aboutHabbit/3.png'
import four from '../../assets/aboutHabbit/4.png'
import five from '../../assets/aboutHabbit/5.png'
import sixEight from '../../assets/aboutHabbit/siedlisko.png'

export default function AboutHabitat() {

    return (
        <>
            <section id="aboutHabitat">
                <div class="container">
                    <div className="title_headling">
                                        <div style={{display: 'flex', justifyContent: 'center' }}>
                        <img src={sixEight} alt="sixEight" style={{ height: '40%', width: '50%'}}/>
                    </div>
                        <h3>SIEDLISKO SIEDŹ BLISKO</h3>
                        <p>Antystresownia – Instytut Psychologii Społecznej wprowadza nowy program
                            terapeutyczny w Lesie „Siedlisko Siedź Blisko”. Szkolenia psychologiczne na
                            łonie natury, szczególnie w lesie, stają się coraz bardziej popularne ze względu
                            na ich unikalne korzyści. Tego rodzaju szkolenia, często nazywane również
                            terapią leśną (forest therapy) lub ekoterapią, łączą tradycyjne metody
                            terapeutyczne z naturalnym otoczeniem.</p>
                    </div>
    
                    <div className="aboutHabitat_box_wrapper">
                        <div className="aboutHabitat_box">            
                            <img  className="aboutHabitat_icon" src={one} alt="one" />
                            <div className="aboutHabitat_content">
                                <h5 style={{marginTop: '0px'}}>Otoczenie Naturalne</h5>
                                <ul style={{color: 'white', padding: '0', margin: '0', textAlign: 'left'}}>
                                    <li>Bliskość natury: Uczestnicy spędzają czas w naturalnym otoczeniu, co samo
                                        w sobie działa uspokajająco i redukuje stres. Las, ze swoją zielenią, ciszą i
                                        świeżym powietrzem, sprzyja wyciszeniu i refleksji.</li>
                                    <li>Kontakt z przyrodą: Regularny kontakt z przyrodą może poprawić nastrój,
                                        zmniejszyć objawy depresji i lęku oraz poprawić ogólne samopoczucie.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="aboutHabitat_box">
                             <img  className="aboutHabitat_icon" src={two} alt="two" />
                            <div className="aboutHabitat_content">
                                <h5 style={{marginTop: '0px'}}>Aktywność Fizyczna</h5>
                                <ul style={{color: 'white', padding: '0', margin: '0', textAlign: 'left'}}>
                                    <li>Spacerowanie: Duża część terapii leśnej polega na spacerach po lesie, które
                                        mogą być zarówno powolne i refleksyjne, jak i bardziej dynamiczne, w
                                        zależności od celu szkolenia.</li>
                                    <li>Ćwiczenia: W zależności od programu, mogą być włączone ćwiczenia
                                        fizyczne, takie jak joga na świeżym powietrzu, medytacja czy ćwiczenia
                                        oddechowe.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="aboutHabitat_box">
                         <img  className="aboutHabitat_icon" src={three} alt="three" />
                            <div className="aboutHabitat_content">
                                <h5 style={{marginTop: '0px'}}>Praktyki Mindfulness</h5>
                                <ul style={{color: 'white', padding: '0', margin: '0', textAlign: 'left'}}>
                                    <li>Medytacja: Szkolenia często obejmują techniki medytacyjne, które
                                        pomagają uczestnikom skupić się na chwili obecnej i zredukować stres.</li>
                                    <li>Świadome obserwowanie: Uczestnicy są zachęcani do uważnego
                                        obserwowania otaczającej przyrody, co sprzyja rozwijaniu umiejętności
                                        uważności i obecności.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="aboutHabitat_box">
                             <img  className="aboutHabitat_icon" src={four} alt="four" />
                            <div className="aboutHabitat_content">
                                <h5 style={{marginTop: '0px'}}>Zajęcia Grupowe i Indywidualne</h5>
                                <ul style={{color: 'white', padding: '0', margin: '0', textAlign: 'left'}}>
                                    <li>Sesje grupowe: Zajęcia mogą obejmować grupowe sesje terapeutyczne,
                                        gdzie uczestnicy dzielą się swoimi doświadczeniami i wspierają nawzajem.</li>
                                    <li>Sesje indywidualne: Możliwe są również indywidualne sesje z terapeutą,
                                        skoncentrowane na specyficznych potrzebach uczestnika.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="aboutHabitat_box">
                                    <img  className="aboutHabitat_icon" src={five} alt="five" />
                            <div className="aboutHabitat_content">
                                <h5 style={{marginTop: '0px'}}>Elementy Edukacyjne</h5>
                                <ul style={{color: 'white', padding: '0', margin: '0', textAlign: 'left'}}>
                                    <li>Nauka o przyrodzie: Uczestnicy mogą dowiedzieć się więcej o lokalnej florze
                                        i faunie, co wzmacnia ich więź z naturą.</li>
                                    <li>Umiejętności przetrwania: Niektóre programy mogą obejmować
                                        podstawowe umiejętności przetrwania w lesie, takie jak rozpalanie ognia,
                                        budowanie schronienia czy rozpoznawanie jadalnych roślin.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
