import React from 'react';
import './Home.css';
import BannerImage from '../../assets/banner/instytut_psychologi.png';

export default function Home() {

  return (
    <>
    <section id="home">
        <div className="banner_image"></div>
        <div className="container">
            <div className="banner_outer">
                <div className="col">
                    <h3 className="title">
                        Witaj w Antystresowni
                    </h3>
                    <p>w miejscu, gdzie Twoje dobre samopoczucie i zdrowie
                        psychiczne stają się priorytetem. Nazywam się Michał Karpeza i jako
                        doświadczony psycholog, oferuję kompleksowe wsparcie psychologiczne w
                        oparciu o metody psychologii pozytywnej. Ukończyłem Uniwersytet Gdański
                        oraz Uniwersytet SWPS, a moje specjalizacje obejmują psychologię ogólną,
                        komunikację społeczną, interwencję kryzysową, diagnozę psychologiczną oraz
                        psychologię w organizacji i reklamie. Dodatkowo specjalizuję się w kreowaniu
                        wizerunku, doradztwie zawodowym oraz coachingu.</p>
                </div>
                <div className="col">
                    <div className="sub_banner_image">
                        <img src={BannerImage} alt="Banner_image" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
