import React, { useState, useEffect } from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
    const date = new Date();
    const year = date.getFullYear();

    // Icons
    const faArrowUpIcon = <FontAwesomeIcon icon={faArrowUp} />;

    // State
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const listenToScroll = () => {
        let heightToHidden = 250;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;

        (windowScroll > heightToHidden) ? setIsVisible(true) : setIsVisible(false);
    };

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []);

    return (
        <>
            <section className="container">
                <footer className="footer">
                    <p>© All Right Reserved {year}</p>
                    <p> Created by <a href="mailto:zielinskideveloper@gmail.com" style={{margin: '0px 4px'}}>zielinskideveloper</a></p>
                </footer>
            </section>

            {
                isVisible && (
                    <div className="scroll_top" onClick={scrollToTop}>
                        {faArrowUpIcon}
                    </div>
                )
            }
        </>
    );
}
